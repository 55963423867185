<template>
  <div :class="['has-background-warning pb-2 mb-4 px-4 rounded-lg text-black relative', { 'mx-8 mt-36': !this.isMobile, 'w-full': this.isMobile }]" :style="containerStyles">
    <img :src="img" class="absolute pb-1 md:mt-5" :style="imageStyles" />
    <h5 class="font-bold xl:text-md xl:mt-3">Hi, aku Mico</h5>
    <p class="text-xs md:text-sm w-4/5 md:w-full">Bagi anda yang pertama kali menggunakan apps iPOST, yuk ikuti Tour nya</p>
    <b-button type="is-primary-gradient mt-2" @click="$emit('click')">Start Tour</b-button>
  </div>
</template>

<script>
export default {
  computed: {
    imageStyles () {
      if (this.isMobile) {
        return {
          height: '100px',
          top: '-50px',
          right: '-10px',
          transform: 'scaleX(-1)'
        }
      }

      return {
        top: '-120px',
        height: '200px',
        left: '-20px',
        transform: 'scaleX(-1)'
      }
    },
    containerStyles () {
      if (this.isMobile) return { paddingTop: '1rem' }

      return {
        paddingTop: '80px'
      }
    },
    img () {
      return this.company.MascotImages.Tour
    }
  }
}
</script>

<style>

</style>
